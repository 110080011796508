<template>
    <div class="d-block w-100">
        <div id="accordion2-1">
            <div id="subnav-1-2" class='pt-2'>
                <p class="position-relative collapsed link lv-1" tabindex="1" role="button" 
                v-b-toggle="'subnav-collapse-1-'+idx"
                >
                    <span class='lv-1-name'>{{ name }}</span>
                    <span class="icon when-close">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" class="mt-1"/>
                    </span>
                    <span class='icon when-open'>
                        <font-awesome-icon :icon="['fas', 'chevron-up']" class="mt-1"/>
                    </span>
                
                </p>
            </div>
            <b-collapse :id="'subnav-collapse-1-' + idx" accordion="subnav-1-1" visible>
                <ul v-for='(section,index) in sections' class='section'>
                    <router-link class="link-2" :id="'link-2-' + idx + '-' + index" 
                        :to="'#' + section.hash" 
                        @click.native='close'> 
                        {{ section.name }}
                    </router-link>
                    <SubNavThree :items='section.links' 
                    :sectionIdx='idx'
                    :blockIdx='index'
                    :currentSecHash='currentSecHash'/>
                </ul>
            </b-collapse>
        </div>
    </div>
</template>
<script>
import SubNavThree from '@/components/SubNavThree'
/* Handles the Sections */ 



export default {
        name: 'SubNavTwo',
        data() {
            return {

            }
        },
        components: {
            SubNavThree
        },
        props: [
            'name',
            'hash',
            'sections',
            'idx',
            'currentSecHash'
        ],
        methods: {
            toggleActive() {
                this.active = !this.active
            },
            
            close() {
                if(document.documentElement.clientWidth > 420) {}
                else {
                    //close 2 level
                    this.$root.$emit('bv::toggle::collapse', 'subnav-collapse-1')
                }

            },


        }
    }
</script>
<style scoped>
    .section {
        margin: 10px 10px;
    }

    @media(min-width: 768px){
        .section {
            margin: 5px;
        }
    }

    @media(min-width: 900px){
        .section {
            margin: 10px;
        }    

    }

    p.lv-1 {
        display: flex;
    }

    p.lv-1 > .lv-1-name {
        padding-right: 5px;
        width: 100%;
    }

    .link.lv-1[aria-expanded=true] {
        color: #FFAD00;
    } 
    .link.lv-1[aria-expanded=true] .when-close{
        display: none;
    }
    .link.lv-1[aria-expanded=false] .when-open{
        display: none;
    }


    .link-2.router-link-active {
        color: #FFAD00;
    }    

</style>