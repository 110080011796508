<template>
    <section class="page-intro justify-content-center">
        <div class="intro-img">
            <img class="fit-cover" :src="intro.img" alt="">
            <div class="text-container">
                <h2>{{ intro.title }}</h2>
                <h4 v-if="intro.secondTitle">{{ intro.secondTitle }}</h4>
            </div>
            <p class="credits" v-if="intro.credit">{{ intro.credit }}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Intro',
    props: {
        intro: {
            type: Object,
            default: null
        }
    }
}
</script>
