var level_2_0 = {
  '0' : {
    name : 'Community Care Day (CCD) 2020',
    hash : 'CommunityCareDay2020',
  },
  "1" : {
    "name": "Friends of Community Care (FOCC) Awards 2020",
    "hash": "FriendsCommunityCareAwards2020"
  },
  "2" : {
    "name": "Community Care Excellence Awards (CCEA) 2020",
    "hash": "CommunityCareExcellenceAwards2020"
  },
  '3' : {
    name : 'Quality Improvement (QI) Celebration',
    hash : 'QualityImprovementCelebration',
  }
}

var level_2_1 = {
  '0' : {
    name: 'Clinical Quality Improvement (CQI)',
    hash: 'ClinicalQualityImprovement',
  },
  '1' : {
    name: 'Nursing Home Safety Culture Survey',
    hash: 'NursingHomeSafetyCultureSurvey',
  },
  '2' : {
    name: 'Potentially Avoidable Hospitalisations',
    hash: 'PotentiallyAvoidableHospitalisations',
  },
}

var level_3_3_0 = {
  "0" : {
    "name": "Adaptive Sports",
    "hash": "AdaptiveSports"
  },
  "1" : {
    "name": "Arts-based Activities",
    "hash": "ArtsBasedActivities"
  },
  "2" : {
    "name": "Heritage Activities",
    "hash": "HeritageActivities"
  },
  "3" : {
    "name": "Intergenerational Activities",
    "hash": "IntergenerationalActivities"
  },
}

var level_3_3_1 = {
  "0" : {
    "name": "Facilitator Training",
    "hash": "FacilitatorTraining"
  },
  "1" : {
    "name": "Wellness Support Package (WSP)",
    "hash": "WellnessSupportPackage"
  },
  "2" : {
    "name": "Other Wellness Resources",
    "hash": "OtherWellnessResources"
  },
}

var level_3_4_0 = {
  "0" : {
    "name": "AIC Learning Network",
    "hash": "AICLearningNetwork"
  },
  "1": {
    "name": "IGNITE Leadership",
    "hash": "IGNITELeadership"
  },
  "2": {
    "name": "IMPACT Leadership",
    "hash": "IMPACTLeadership"
  },
  "3": {
    "name": "INSIGHT Leadership",
    "hash": "INSIGHTLeadership"
  },
  "4": {
    "name": "INSPIRE Leadership",
    "hash": "INSPIRELeadership"
  },
}

var level_3_4_1 = {
  "0": {
    "name": "Community Care Manpower Development Awards (CCMDA)",
    "hash": "CommunityCareManpowerDevelopmentAwards"
  },
  "1": {
    "name": "Community Care Scholarship (CCS) & Community Nursing Scholarship (CNS)",
    "hash": "CommunityCareScholarshipCommunityNursingScholarship"
  },
}

var level_3_4_2 = {
  "0" : {
    "name": "Community Care Learning Journeys & Experiential Programmes",
    "hash": "CommunityCareLearningJourneys"
  },
  "1" : {
    "name": "#CouragetoCare Webinar",
    "hash": "CouragetoCareWebinar"
  },
  "2" : {
    "name": "Healthcare Scholarships Virtual Experience 2021",
    "hash": "HealthcareScholarshipsVirtualExperience"
  },
  "3" : {
    "name": "Job Fairs",
    "hash": "JobFairs"
  },
  "4" : {
    "name": "Branding the Sector",
    "hash": "BrandingSector"
  },
  "5" : {
    "name": "mosAIC",
    "hash": "mosAIC"
  },
}

var level_2_2 = {
  '0' : {
    name: 'Healthcare Productivity Fund (HPF)',
    hash: 'HealthcareProductivityFund',
  },
  '1' : {
    name: '6S Workshops',
    hash: '6SWorkshops',
  }
}

var level_2_3 = {
  '0' : {
    name: 'New Wellness Activities',
    hash: 'NewWellnessActivities',
    links: level_3_3_0,
  },
  '1' : {
    name: 'Resources for Partners',
    hash: 'ResourcesPartners',
    links: level_3_3_1,
  },
}

var level_2_4 = {
  '0' : {
    name: 'Upskilling the Workforce',
    hash: 'UpskillingWorkforce',
    links: level_3_4_0,
  },
  '1' : {
    name: 'Study Awards & Scholarships',
    hash: 'StudyAwardsScholarships',
    links: level_3_4_1,
  },
  '2' : {
    name: 'Career & Scholarship Outreach',
    hash: 'CareerScholarshipOutreach',
    links: level_3_4_2,
  },
  '3' : {
    name: 'Community Care Salary Enhancement',
    hash: 'CommunityCareSalaryEnhancement',
  },
}

var level_2_5 = {
  '0' : {
    name: 'Community Silver Trust',
    hash: 'CommunitySilverTrust'
  },
  '1' : {
    name: 'Tote Board Community Health Fund',
    hash: 'ToteBoardCommunityHealthFund'
  },
}


var level_1 = {
  '0' : {
    name: 'Recognising the Sector',
    sections: level_2_0,
    active: false
  },

  '1' : {
    name: 'Enhancing Quality',
    sections: level_2_1,
    active:false
  },

  '2' : {
    name: 'Boosting Productivity',
    sections: level_2_2,
    active:false
  },
  '3' : {
    name: 'Introducing Wellness Activities',
    sections: level_2_3,
    active:false
  },
  '4' : {
    name: 'Enhancing Manpower Capabilities',
    sections: level_2_4,
    active:false
  },
  '5' : {
    name: 'Sector Grants',
    sections: level_2_5,
    active:false
  }
}

export default level_1; 