<template>
    <section class="content-container row justify-content-center" id='description' name='Support for partners'>
        <div class="col-12 px-3">
            <p>Community Care organisations are a key pillar in delivering seamless care to seniors and clients, and empowering them to age with dignity. AIC is committed to supporting our partners by connecting them to resources, sharing best practices, enhancing training opportunities, building a pipeline of talent, and recognising the heroes of community care.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
}
</script>
