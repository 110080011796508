<template>
    <div>
        <section id='CommunityCareDay2020' class="content-container" name='Community Care Day (CCD) 2020'>
            <div class="titles">
                <h2>Recognising the Sector</h2>
                <h4>Community Care Day (CCD) 2020</h4>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/jSc9akd64i8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p>The annual CCD, which falls on 1 November, is dedicated to recognising the hard work of everyone in the Community Care sector. <br><br> In conjunction with CCD 2020, AIC launched a ‘Thank You’ campaign to pay tribute to Community Care staff through advertisements and a thankyouproject.sg website where the public could submit letters of appreciation. The celebration was capped by a virtual event on 30 October 2020 graced by Minister for Social and Family Development and Second Minister for Health Mr Masagos Zulkifli.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_1.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">700</span> letters of appreciation from the public were received.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='FriendsCommunityCareAwards2020' class="content-container" name='Friends of Community Care Awards'>
            <div class="titles">
                <h4>Friends of Community Care (FOCC) Awards 2020</h4>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/Ys6zhiQMYrU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p>At the virtual CCD event, we held the inaugural FOCC Awards, which recognise partners outside the Community Care sector for their strong support towards the sector.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_2.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p><span class="stats">12</span> organisations were recognised at the inaugural FOCC Awards.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            <section id='CommunityCareExcellenceAwards2020' class="content-container" name='Community Care Excellence Awards'>
            <div class="titles">
                <h4>Community Care Excellence Awards (CCEA) 2020</h4>
            </div>

            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/Mk7-9IdmWvY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    
                </iframe>
            </div>

            <div class="col-12 px-3">
                <p>We also recognised the winners of CCEA at the same virtual event. Introduced in 2014, the biennial awards honour individuals and teams for their exemplary service and commitment in delivering quality care. It is the only national quality award dedicated solely to the Community Care sector.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_3.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">300</span> individual and team winners in <span class="stats">5</span> categories received awards.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'CommunityCareDay2020',
}
</script>
