<template>
    <div>
        <section id='QualityImprovementCelebration' class="content-container" name='Quality Improvement Celebration'>
            <div class="titles">
                <h4>Quality Improvement (QI) Celebration</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_1.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">ECON Medicare Centre participants receiving their certificate of achievement</p>
            <div class="col-12 px-3">
                <p>Since 2017, AIC has facilitated numerous QI collaboratives with sector partners. On 28 November 2019, we threw a celebration to share experiences, launch two handbooks on best practices, recognise past participants, and inspire more organisations to participate.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_4.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Close to <span class="stats">140</span> staff from over <span class="stats">40</span> nursing homes attended the QI Celebration.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'QualityImprovementCelebration',
}
</script>
