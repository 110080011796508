<template>
    <div>
        <section id='NewWellnessActivities' class="content-container" name='New Wellness Activities'>
            <div class="titles">
                <h2>Introducing Wellness Activities</h2>
                <p >The AIC Wellness Programme is an initiative that enhances wellbeing and quality of life for Community Care clients by expanding the variety of engaging and meaningful activities available to them.</p>
            </div>
            <div class="titles">
                <h4>New Wellness Activities</h4>
            </div>
        </section>
        <section id='AdaptiveSports' class="content-container" name='Adaptive Sports'>
            <h5 class="px-3">Adaptive Sports</h5>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/ueIiAVohYHY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p >In collaboration with SportCares (Sport Singapore), the seniors in Sree Narayana Mission Nursing Home and Senior Care Centre trialed seven adaptive sports between May and August 2019. These are sports modified for seniors using wheelchairs with physical or cognitive conditions, such as stroke and early dementia.<br><br> The successful pilot led to the development of the Fit & Fun Adaptive Sports Toolkit and Adaptive Sports Training, in collaboration with SportCares (Sport Singapore), to guide Community Care partners in conducting adaptive sports for their clients. <a href='https://partners.aic.sg/productivity-quality/aic-wellness-programme'>The full volume of the toolkit</a> will be launched in 2021.</p>
            </div>
        </section>
        <section id='ArtsBasedActivities' class="content-container" name='Arts-based Activities'>
            <div class="titles">
                <h5>Arts-based Activities</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_5.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">The Arts, Ageing and Wellbeing Toolkit is available <a href='https://partners.aic.sg/productivity-quality/aic-wellness-programme'>online</a></p>
            <div class="col-12 px-3">
                <p >AIC also developed other toolkits and booklets to facilitate arts-based activities and made them available to the public and sector on our website. <a href='/support-public#KeepingSeniorsEngaged'>Three booklets were produced to keep seniors engaged during COVID-19</a>. In collaboration with the Nanyang Technological University, we also produced an Arts, Ageing and Wellbeing Toolkit comprising eight arts-based activities for Community Care clients and seniors in the community.</p>
            </div>
        </section>
        <section id='HeritageActivities' class="content-container" name='Heritage Activities'>
            <div class="titles">
                <h5>Heritage Activities</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_6.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">An NTUC Health client trying out the award-winning app created by National Museums Liverpool (NML) from the United Kingdom</p>
            <div class="col-12 px-3">
                <p >‘My House of Memories’ leverages the power of familiarity to elicit memories in persons with dementia as well as encourage reminiscence and interaction with their caregivers.<br><br> AIC worked with the National Heritage Board (NHB) and NTUC Health to make the app’s content relevant for locals. The app was launched in Singapore on 28 September 2020, and can be downloaded via <a href="https://apps.apple.com/sg/app/my-house-of-memories/id847763460 ">App Store</a> and <a href="https://play.google.com/store/apps/details?id=com.nml.myhouseofmemories">Google Play Store</a>.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_11.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>More than <span class="stats">300 </span> Community Care staff and caregivers have been trained by NHB and NML to use the app.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='IntergenerationalActivities' class="content-container" name='Intergenerational Activities'>
            <div class="titles">
                <h5>Intergenerational Activities</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_7.jpg" alt="">
                    <p class="credits">Credit: Thye Hua Kwan Nursing Home @ Hougang</p>
                </div>
            </div>
            <p class="caption px-3">A Youth Corps Singapore volunteer interacts virtually with a nursing home resident</p>
            <div class="col-12 px-3">
                <p >Since 2016, we have fostered intergenerational partnerships to provide opportunities for the older and younger generations to interact, learn and bond. <br><br>Due to COVID-19 restrictions, Youth Corps Singapore volunteers involved in the YOLDEN initiative at three nursing homes have switched from physical activities to virtual sessions since May 2020. <br><br>Besides our ongoing partnership with the PAP Community Foundation (PCF), we collaborated with Community Chest under the Start Small Dream Big (SSDB) initiative by the Early Childhood Development Agency to encourage interactions between pre-schoolers and seniors in Community Care facilities. In view of COVID-19 restrictions, pre-schoolers have also been connecting with seniors and bringing them joy virtually.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_12.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Under the SSDB initiative, partnerships have been fostered between <span class="stats">5 </span> preschools and <span class="stats">5 </span> nursing homes.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_13.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">70</span> PCF preschools have connected with close to <span class="stats">20</span> Community Care sites.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ResourcesPartners' class="content-container" name='Resources for Partners'>
            <div class="titles">
                <h4>Resources for Partners</h4>
            </div>
        </section>
        <section id='FacilitatorTraining' class="content-container" name='Facilitator Training'>
            <div class="titles">
                <h5>Facilitator Training</h5>
            </div>
            <div class="col-12 px-3">
                <p >We have been organising training courses to equip staff with the skills to facilitate AIC Wellness Programme activities in areas such as art, sport, creative movement, music and gardening.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_14.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">1,000</span> staff from <span class="stats">180</span> facilities have been trained to facilitate wellness activities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='WellnessSupportPackage' class="content-container" name='Wellness Support Package (WSP)'>
            <div class="titles">
                <h5>Wellness Support Package (WSP)</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_8.jpg" alt="">
                    <p class="credits">Credit: Lions Home For the Elders</p>
                </div>
            </div>
            <p class="caption px-3">A nursing home resident nurturing plants as part of Social and Therapeutic Horticulture, one of the activities supported by WSP</p>
            <div class="col-12 px-3">
                <p >Started in 2019, this three-year initiative is supported by the Tote Board Community Health Fund. It provides funding for the training of staff and volunteers at nursing homes to improve their capabilities to enhance the frequency, quality and variety of recreational activities for residents. Some of the activities the homes have introduced to residents include horse therapy, boxing and flower arrangement.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_15.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Over <span class="stats">30</span> nursing homes have tapped on WSP and introduced more than <span class="stats">50</span> new activities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='OtherWellnessResources' class="content-container" name='Other Wellness Resources'>
            <div class="titles">
                <h5>Other Wellness Resources</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_9.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">This guide (available <a href='https://partners.aic.sg/productivity-quality/aic-wellness-programme'>online</a>) offers tips to staff and volunteers who plan and conduct group activities for frail seniors and clients</p>
            <div class="col-12 px-3">
                <p >In 2020, we published the activity facilitation guide ‘Planning Effective Group Activities’ for Community Care partners. On 24 November 2020, we organised a Wellness Webinar to share activity resources by non-Community Care partners (e.g. Vintage Radio SG, NHB, Apple) for the sector to tap on to engage their clients.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_16.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Around <span class="stats">70</span> participants from Community Care attended the Wellness Webinar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'IntroducingWellnessActivities',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
<style scoped>
</style>
