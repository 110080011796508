<template>
    <div>
        <section id='ClinicalQualityImprovement' class="content-container" name='Clinical Quality Improvement'>
            <div class="titles">
                <h2>Enhancing Quality</h2>
                <h4>Clinical Quality Improvement (CQI)</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_2.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Participants at a falls prevention sharing session</p>
            <div class="col-12 px-3">
                <p>This collaborative initiative, introduced in 2018, aims to help nursing homes improve clinical care and promote best practices. Up to four nursing homes form a collaborative focused on one of six core topics: falls, pneumonia, medication safety, urinary tract infections, physical restraints, and pressure injuries. <br><br> For each collaborative, experts will share current best practices, including clinical knowledge, change management and quality improvement tools to build the teams’ capabilities. The project teams develop interventions such as care bundles for implementation in their institutions.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_5.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Teams from close to <span class="stats">40</span> nursing homes have enrolled in CQI.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='NursingHomeSafetyCultureSurvey' class="content-container" name='Nursing Home Safety Culture Survey'>
            <div class="titles">
                <h4>Nursing Home Safety Culture Survey</h4>
            </div>
            <div class="col-12 px-3">
                <p>Introduced in 2013, this biennial survey assesses the safety culture at nursing homes and generates reports identifying areas for improvement. <br><br> The fourth survey was conducted from December 2019 to February 2020. We also organised a webinar where participants shared best practices, received resources for safety improvement and learnt about upcoming Quality Improvement initiatives.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_6.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p><span class="stats">89%</span> of all nursing homes participated in the survey.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_7.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p><span class="stats">130</span> participants from close to <span class="stats">30</span> nursing homes attended the webinar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='PotentiallyAvoidableHospitalisations' class="content-container" name='Potentially Avoidable Hospitalisations'>
            <div class="titles">
                <h4>Potentially Avoidable Hospitalisations</h4>
            </div>
            <div class="col-12 px-3">
                <p>In 2017, AIC commissioned a two-year study to understand hospitalisation patterns and hospital incidents which may have been avoided at nursing homes. A team of nurses, researchers, geriatricians and other doctors conducted the study in two phases, analysing past records and data. <br><br> The study concluded in 2019. Its report identified areas for improvement, recommended solutions, and presented findings that nursing homes can use to develop strategies to manage increasingly complex care needs.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_8.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">30</span> nursing homes participated in the study.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'EnhancingQuality',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
