<template>
    <div>
        <section id='CommunitySilverTrust' class="content-container" name='Community Silver Trust'>
            <div class="titles">
                <h2>Sector Grants</h2>
                <p>We administer grants that support our Community Care partners’ efforts to improve quality of care.</p>
            </div>
            <div class="titles">
                <h4>Community Silver Trust</h4>
            </div>
            <div class="col-12 px-3">
                <p>This is a dollar-for-dollar government matching grant for donations raised by eligible organisations from the health and social services sector.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_31.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">$850</span> million was awarded to more than <span class="stats">80</span> organisations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='ToteBoardCommunityHealthFund' class="content-container" name='Tote Board Community Health Fund'>
            <div class="titles">
                <h4>Tote Board Community Health Fund</h4>
            </div>
            <div class="col-12 px-3">
                <p>This joint initiative by Tote Board and MOH aims to build a healthier nation, enhance the quality of life of patients, and improve the affordability and accessibility of healthcare for the needy and disadvantaged.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_32.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">$170 million</span> was awarded to <span class="stats">300</span> programmes. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'SectorGrants',
}
</script>
