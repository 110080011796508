<template>
    <div>
        <section id='HealthcareProductivityFund' class="content-container" name='Healthcare Productivity Fund'>
            <div class="titles">
                <h2>Boosting Productivity</h2>
                <h4>Healthcare Productivity Fund (HPF)</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_3.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Seniors with dementia can expect to be supported by robot therapy at Yishun Community Hospital</p>
            <div class="col-12 px-3">
                <p >Community Care partners can tap on the HPF’s three funding initiatives (Technology Adoption, Process Improvement Collaborative, Bulk Procurement and Shared Services) to support productivity and quality improvement efforts. <br><br> Technology adoption can reduce the time and effort required for manpower-intensive processes. Partners can tap on HPF to purchase equipment and receive 85% funding.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_9.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p class="line">Over <span class="stats">260</span> technology adoption projects valued at about <span class="stats">$10 million</span> have been approved so far, saving the sector over <span class="stats">720</span> man-hours daily.</p>
                            <p>There are <span class="stats">27</span>  equipment or services on HPF’s Green Lane which partners can put in express applications for.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='6SWorkshops' class="content-container" name='6S Workshops'>
            <div class="titles">
                <h4>6S Workshops</h4>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_4.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Participants from the sector at a 6S workshop</p>
            <div class="col-12 px-3">
                <p >These new AIC-guided 6S (Sort, Set in order, Shine, Standardise, Sustain, Safety) collaborative workshops improve the productivity of Community Care organisations by equipping participants with basic knowledge in lean methodology, workplace organisation and visual management.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_10.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>In 2019 and 2020, <span class="stats">9</span> Community Care organisations have participated in the workshops and achieved an average of <span class="stats">68%</span> in time savings.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'BoostingProductivity',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
