<template>
    <div>
        <section id='UpskillingWorkforce' class="content-container" name='Upskilling the Workforce'>
            <div class="titles">
                <h2>Enhancing Manpower Capabilities</h2>
                <h4>Upskilling the Workforce</h4>
            </div>
        </section>
        <section id='AICLearningNetwork' class="content-container" name='AIC Learning Network'>
            <div class="titles">
                <h5>AIC Learning Network</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_10.jpg" alt="">
                    <p class="credits">(This photograph was taken prior to COVID-19)</p>
                </div>
            </div>
            <p class="caption px-3">Resuscitation training at HMI Institute of Health Sciences, one of the eight Learning Institutes in this network</p>
            <div class="col-12 px-3">
                <p >The AIC Learning Network, through its eight Learning Institutes, currently offers over 270 courses in five key areas: Clinical; Quality Assurance/Improvement; Behavioural and Psychosocial Health; Leadership and People Management; Governance and Organisational Excellence. <br><br> To support the learning needs of the sector, we launched a Community Care Learning Management System under the AIC Learning Network. Community Care partners can better track and manage staff training at the organisational level, while individuals can use it to browse and select courses to attend.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_17.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>More than <span class="stats">21,500</span> training places have been offered under this network.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='IGNITELeadership' class="content-container" name='IGNITE Leadership'>
            <div class="titles">
                <h5>IGNITE Leadership</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_11.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Participants attending an IGNITE Leadership session</p>
            <div class="col-12 px-3">
                <p >IGNITE Leadership is delivered over three modules for new managers and leaders in Community Care to gain a better understanding of the sector and the various schemes available for seniors, clients and Community Care organisations.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_18.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Close to <span class="stats">150</span>  managers from over <span class="stats">40</span> organisations have attended IGNITE.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='IMPACTLeadership' class="content-container" name='IMPACT Leadership'>
            <div class="titles">
                <h5>IMPACT Leadership</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_12.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">IMPACT Leadership participants at a talk on project management</p>
            <div class="col-12 px-3">
                <p >This experiential and interactive five-day programme offers middle managers in the sector insights to unlock their leadership potential. It empowers participants to define strategies for their organisations, lead teams, raise standards and bring about change.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_19.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">340</span> middle managers from close to <span class="stats">70</span> organisations have attended IMPACT Leadership.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='INSIGHTLeadership' class="content-container" name='INSIGHT Leadership'>
            <div class="titles">
                <h5>INSIGHT Leadership</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_13.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Participants having a group discussion on a case study</p>
            <div class="col-12 px-3">
                <p >INSIGHT Leadership is an eight-day senior leadership programme with an emphasis on manpower, stakeholder management and service excellence. Participants gain a deeper understanding of healthcare policies and operating environments, as well as insights into intra-agency collaboration to foster an integrated healthcare system. Participants also get to engage with senior policy makers from the Ministry of Health (MOH) and the Ministry of Social and Family Development.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_20.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">150</span> senior leaders from more than <span class="stats">60</span> organisations have attended INSIGHT Leadership. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='INSPIRELeadership' class="content-container" name='INSPIRE Leadership'>
            <div class="titles">
                <h5>INSPIRE Leadership</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_14.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Participants at a talk on the importance of succession planning</p>
            <div class="col-12 px-3">
                <p >This is an invitation-only programme for experienced C-Suite executives in the Community Care sector. Over four months and five modules of executive coaching, peer learning and dialogues with leaders from the public, private and non-profit sectors, the programme helps leaders recognise their individual leadership styles, gain insights in partnership governance and financial sustainability, and strengthen their networks within the sector.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_21.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Close to <span class="stats">30</span>  C-suite leaders from <span class="stats">27</span> organisations have attended INSPIRE Leadership. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='StudyAwardsScholarships' class="content-container" name='Study Awards & Scholarships'>
            <div class="titles">
                <h4>Study Awards & Scholarships</h4>
            </div>
        </section>
        <section id='CommunityCareManpowerDevelopmentAwards' class="content-container" name='Manpower Development Awards'>
            <div class="titles">
                <h5>Community Care Manpower Development Awards (CCMDA)</h5>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/hcBDwxkf_fg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p >Open to students, Community Care staff, and mid-career switchers, CCMDA provides opportunities for career growth through further education and knowledge upgrading. A virtual CCMDA Ceremony was held on 16 October 2020, graced by Senior Minister of State for Health Dr Koh Poh Koon, to recognise and celebrate the achievements of award recipients. <br><br> To attract more students to the Community Care sector, we launched a CCMDA microsite and application portal in January 2021 on <a href="https://brightsparks.com.sg/profile/aic/">BrightSparks</a>, Singapore’s top portal for scholarships and higher education.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_22.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">260</span> awards were conferred on recipients from more than <span class="stats">40</span> Community Care organisations from April 2019 to March 2021.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CommunityCareScholarshipCommunityNursingScholarship' class="content-container" name='Community Care Scholarship'>
            <div class="titles">
                <h5>Community Care Scholarship (CCS) & Community Nursing Scholarship (CNS)</h5>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/Ncgqyceh2OE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p >CCS was launched in January 2020 to grow the pool of allied health leaders in the Community Care sector. It complements CNS, which has been developing nursing leaders since 2017.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_23.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>To date, there have been over <span class="stats">10</span> CCS and over <span class="stats">20</span> CNS recipients.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CareerScholarshipOutreach' class="content-container" name='Career & Scholarship Outreach'>
            <div class="titles">
                <h4>Career & Scholarship Outreach</h4>
            </div>
        </section>
        <section id='CommunityCareLearningJourneys' class="content-container" name='Community Care Learning Journeys'>
            <div class="titles">
                <h5>Community Care Learning Journeys & Experiential Programmes</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_15.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">ECON Healthcare gave participants an inside look at their work behind the scenes</p>
            <div class="col-12 px-3">
                <p >Launched with six Community Care organisations in February 2021, this initiative raises awareness of nursing and allied health career and scholarship opportunities. Participants take part in virtual learning journeys and shadow Community Care staff on site to learn first-hand what it takes to work in the sector.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_24.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>We have conducted over <span class="stats">10</span> learning journeys and experiential programmes for more than <span class="stats">200</span> participants.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CouragetoCareWebinar' class="content-container" name='#CouragetoCare Webinar'>
            <div class="titles">
                <h5>#CouragetoCare Webinar</h5>
            </div>
            <div class="col-12 px-3">
                <p >Through this inaugural webinar, AIC partnered MOH Holdings (MOHH) and reached out to cadets of co-curricular activity groups like the St John Brigade who might have a strong interest in a Community Care career. The webinar complements the other digital engagement sessions we organise with educational institutions.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_25.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>More than <span class="stats">100</span> people attended the inaugural webinar in January 2021.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='HealthcareScholarshipsVirtualExperience' class="content-container" name='Healthcare Scholarships Virtual'>
            <div class="titles">
                <h5>Healthcare Scholarships Virtual Experience 2021</h5>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/eMe9gFyDWeA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p >AIC partnered MOHH to host three days (1 to 3 March) of the 14-day virtual event to share about the sunrise Community Care sector and its diverse career experience and opportunities with potential scholarship applicants from junior colleges and institutes of higher learning.</p>
            </div>
        </section>
        <section id='JobFairs' class="content-container" name='Job Fairs'>
            <div class="titles">
                <h5>Job Fairs</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_16.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">Online platforms publicising job openings in the sector</p>
            <div class="col-12 px-3">
                <p >Taking recruitment outreach online, AIC created a <a href="https://vcf.mycareersfuture.gov.sg/healthcare">Healthcare portal</a> on Workforce Singapore’s virtual career fair platform to publicise job openings. We also started a dedicated page on the AIC website to publicise recruitment events.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_26.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>About <span class="stats">30%</span> of the close to <span class="stats">60</span> Community Care job fairs in 2020 were virtual events.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_27.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>More than <span class="stats">50</span> Community Care organisations participated in job fairs in 2020. Over <span class="stats">13,000</span> applications were received, <span class="stats">3</span> times the number received in 2019.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='BrandingSector' class="content-container" name='Branding the Sector'>
            <div class="titles">
                <h5>Branding the Sector</h5>
            </div>
            <div class="col-12 secondary-img-container">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/On662ehrOpA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="col-12 px-3">
                <p >In September 2020, we launched a two-part sector branding campaign to drive Community Care recruitment. It kicked off with the <a href="#CommunityCareDay2020">‘Thank You’ campaign</a>, which was followed by the 'Born To Care' campaign featuring Community Care staff and the work they do. The latter was publicised through radio, digital advertising, and content partnerships with Rice Media and Our Grandfather Story.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_28.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>The 'Born To Care' campaign garnered over <span class="stats">30 million</span> impressions and more than <span class="stats">110,000</span> clicks to the Community Care jobs portal.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='mosAIC' class="content-container" name='mosAIC'>
            <div class="titles">
                <h5>mosAIC</h5>
            </div>
            <div class="col-12 secondary-image-container">
                <div class="img-line">
                    <img src="@/assets/support-partners/img/BAU_Partners_17.jpg" alt="">
                </div>
            </div>
            <p class="caption px-3">A Facebook platform containing news and insights on the Community Care sector</p>
            <div class="col-12 px-3">
                <p >mosAIC continues to profile the sector and lift workforce morale through inspirational stories on its <a href="https://www.facebook.com/SGmosAIC">Facebook</a> page. Last year, we started two new series – #PlayYourPartwithmosAIC, where sector leaders share how they are staying safe in the pandemic; and United We Stand, where Community Care professionals rally the sector with words of encouragement.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_29.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>The mosAIC Facebook page has over <span class="stats">15,000</span> followers. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id='CommunityCareSalaryEnhancement' class="content-container" name='Community Care Salary Enhancement'>
            <div class="titles">
                <h4>Community Care Salary Enhancement</h4>
            </div>
            <div class="col-12 px-3">
                <p >Against the backdrop of a challenging labour market, AIC is working with MOH to roll out this initiative over three phases between 2020 and 2023 to enhance the salary competitiveness of local nursing and support care staff.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-partners/infographics/BAU_Partners_Infographics_30.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Close to <span class="stats">1,300</span> care staff benefited from salary adjustments in the initial rollout.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'EnhancingManpowerCapabilities',
}
</script>
<style scoped>
</style>
