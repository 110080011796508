<template>
	<div id="support-partners" :class='fontsize'>
    
		<Intro :intro="intro" />

		<div class="container-fluid secondary-section fontsize" id="secondary-content">
			<div class="row">
  			 
  			  <SubNavOne :navLinks='navLinks'/>

			    <section id='Navigation' class="content ml-auto">

					<Description/>					
					
					<CommunityCareDay/>

					<QualityImprovementCelebration/>

					<EnhancingQuality/>

                    <BoostingProductivity/>

                    <IntroducingWellnessActivities/>

                    <EnhancingManpowerCapabilities/>

                    <SectorGrants/>

				</section>

				<PageNavigation :prev="'/support-seniors'" :next="'/looking-ahead'" />

			</div>
		</div>
	</div>	
</template>

<script>
// @ is an alias to /src
import SubNavOne from '@/components/SubNavOne.vue'
import NavLinks from '@/components/support-partners/navLinks.js'

import Intro from '@/components/Intro.vue'
import Description from '@/components/support-partners/Description.vue'
import CommunityCareDay from '@/components/support-partners/CommunityCareDay.vue'
import QualityImprovementCelebration from '@/components/support-partners/QualityImprovementCelebration.vue'
import EnhancingQuality from '@/components/support-partners/EnhancingQuality.vue'
import BoostingProductivity from '@/components/support-partners/BoostingProductivity.vue'
import IntroducingWellnessActivities from '@/components/support-partners/IntroducingWellnessActivities.vue'
import EnhancingManpowerCapabilities from '@/components/support-partners/EnhancingManpowerCapabilities.vue'
import SectorGrants from '@/components/support-partners/SectorGrants.vue'

import PageNavigation from '@/components/PageNavigation.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
		name: 'SupportPartners',

    data() {
        return {
            scroll: 0,
            sections: '',
            currentSec: '',
            navLinks : NavLinks,
            hashes: [
            ],
            intro: {
                title: 'Building a stronger care community',
                secondTitle: 'Support for partners',
                img: this.mobileAndTabletCheck() ? require("@/assets/support-partners/banner/mobile.jpg") : require("@/assets/support-partners/banner/desktop.jpg")
            }
    	}
    },

    mixins: [dynamicFontSizeMixin],

    components: {
        SubNavOne,
        Intro,
        Description,
        CommunityCareDay,
        QualityImprovementCelebration,
        EnhancingQuality,
        BoostingProductivity,
        IntroducingWellnessActivities,
        EnhancingManpowerCapabilities,
        SectorGrants,
        PageNavigation
    },
}
</script>

<style>
 /* media */
@media (min-width: 768px) {
	.content{
	 	width: 70vw;
	}
}
</style>