<template>
    <ul :id="'section-'+sectionIdx" class='menu-3'>
     <li v-for='item in items'>
            <router-link class="link-3" :class="isActive(item.hash)" :to="'#' + item.hash" @click.native="close">    
                <font-awesome-icon :icon="['fas', 'caret-right']" class="mx-1"/>
                {{ item.name }}
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name : 'SubNavThree',
    props: [
        'items',
        'sectionIdx',
        'blockIdx',
        'currentSecHash'
    ],
    methods: {
        close() {
            if(document.documentElement.clientWidth >= 768) {}
            else {
                //close 2 level
                this.$root.$emit('bv::toggle::collapse', 'subnav-collapse-1')

            }  
            this.link2active()
        },

        isActive(hash) {
           var itemHash = `#${hash}`;
           if('#' + this.currentSecHash == itemHash) return 'active';
           else return '';
        },

        link2active() {
            var q = '#link-2-' + this.sectionIdx + '-' + this.blockIdx;
            var eles = document.getElementsByClassName("link-2")
            eles.forEach( (ele) => {
                ele.classList.remove('router-link-active')
            } )

            console.log(q)
            var ele = document.querySelector(q)

            ele.classList.add('router-link-active')
        }

    },

}

</script>

<style scoped>
    .active {
        color: #FFAD00 !important;
    }
</style>