<template>
    <section class="row justify-content-between w-100 px-3 my-5">
         
        <button type="button" class="btn text-dark back" @click='goPrev'>
        	<span> <font-awesome-icon :icon="['fas', 'chevron-left']"/></span> Back
        </button>
        <span v-if="$route.name != 'Contributors'">
            <button type="button" class="btn btn-warning text-dark next" @click='goNext'>
            	<span> Next <font-awesome-icon :icon="['fas', 'chevron-right']"/></span>
            </button>
        </span>
    </section>
</template>

<script>
export default {
    name: 'PageNavigation',

    props: ['prev','next'],

    methods: {
        goNext() {
            this.$router.push(this.next)
        },
        goPrev() {
            this.$router.push(this.prev)
        }
    }
}
</script>

<style scoped>
    .btn.back , .btn.next{
        font-family: "Frutiger Neue LT Bold";
        font-size: 16px !important;
        z-index: 1;
    }

@media (min-width: 768px) {
    .btn.back , .btn.next{
        width: 150px;
        height: 50px;
    }
}

</style>
