<template>
    <!-- This is the mobile version !-->
    <div class="sub-menu d-block row justify-content-center fixed-top" id="sub-menu">
        <div class="text-left w-100" id="accordion2">
            <div class="">
                <!-- Mobile Only Level 1 Menu !-->
                <div class="" id="subnav-1" v-show="scroll > 60">
                    <p class="collapsed link lv-0" tabindex="1" role="button" v-b-toggle.subnav-collapse-1 >
                        {{currentSec}} 
                        <span class="icon">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" class="mt-1"/></span>
                    </p>
                </div>
                <!-- end mobile !-->
                
                <b-collapse id="subnav-collapse-1" class="pl-5 pr-4 subnav-content" data-parent="#accordion2" aria-labelledby="subnav-1" v-model="subnav_1">
                   
                    <SubNavTwo v-for='(nav_link,idx) in nav_links' 
                        :name='nav_link.name' 
                        :hash='nav_link.hash'
                        :idx='idx'
                        :currentSecHash='currentSecHash'
                        :sections='nav_link.sections'/>
                    
                </b-collapse>
            </div>
        </div>
    </div>  
</template>

<script>
/*
    One -> Section -> Items 
    Step1: import NavLinks from '@/components/covid/navLinks.js' (create a new version)
    Step2: Set as a variable navLinks : NavLinks
    Step3: add component tag <SubNavOne :navLinks='navLinks'/>
    Step4: add id and name to <section id='together' name='Together'>
    Step5: set page body content secondary-content
 */


import SubNavTwo from '@/components/SubNavTwo.vue'


export default {
    name: 'SubNavOne',
    components: {
        SubNavTwo
    },
    props:['navLinks'],

    data(){
        return {
            subnav_1: false,
            subnav_1_1: false,
            nav_links : '',
            currentSec:'',
            currentSecHash:'',
            scroll:0,
        }
    },
    mounted(){
        this.nav_links = this.navLinks;
        window.addEventListener("resize", this.onResizeWindow);
        window.addEventListener("scroll", this.fixedSubMenu);        
        this.onResizeWindow();
        
        window.addEventListener("scroll", this.onScroll)

    },

    updated() {
        
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.onResizeWindow);
        window.removeEventListener("scroll", this.fixedSubMenu);
        window.removeEventListener("scroll", this.onScroll)
    },
    methods: {
        //hides the menus 
        onResizeWindow: function(){
            if(window.innerWidth>=768){
                this.subnav_1 = true;
                this.subnav_1_1 = true;
            }else{
                this.subnav_1 = false;                
                this.subnav_1_1 = false;
            }
            this.fixedSubMenu();
        }, 
        fixedSubMenu(e) {
            let content = document.getElementById('secondary-content');
            let el = document.getElementById('sub-menu');
            let mainNav = document.getElementById('main-navigation');

            if(window.innerWidth>=768 && window.scrollY> content.offsetTop-30){
                mainNav.style.position="";
                el.style.position = "fixed";
                el.style.top = "80px";
            }else if(window.innerWidth>=320 && window.innerWidth<768) {
                mainNav.style.position="fixed";
            }else{
                el.style.position = "";                
                el.style.top = "";
            }
        },

        //Moves the section hashs 
        onScroll() {
            var scroll = window.top.scrollY
            this.scroll = scroll;
            var elem = '';
            var sectionName = ''
            var sectionHash = ''
            this.sections = this.getSections()

            this.sections.forEach( section => {
                if(scroll > (section.top - 20) && scroll < section.bottom) {
                    sectionName = section.name
                    sectionHash = section.id
                }
            })
             this.currentSec = sectionName
             this.currentSecHash = sectionHash

        },

        //Get all sections in document {id, name, top and bottom}
        getSections() {
            var sections = document.querySelectorAll('section');
            var a = [];
            var adjust_ = 0;
            if(document.documentElement.clientWidth > 760) adjust_ = 56;
            else adjust_ = 108

            sections.forEach( section => {
                var s = { 
                    name : section.getAttribute("name"),
                    id : section.id,
                    top : section.offsetTop - adjust_,
                    bottom: section.offsetTop + section.offsetHeight - adjust_
                }
                if(section.id != '' && s.name != null) a.push(s)
                
           })
           return a     
        }, 
    },


    watch: {        

    }
  
}
</script>

<style>
    

    /*SubNavOne*/
    .sub-menu{
        z-index: 1;
    }


    /*mobile only menu*/
    #subnav-1 {
        padding: 5px 20px 0px 50px;
        margin-top: 30px;
    }

    @media(min-width: 360px){ 
        #subnav-1 {
            margin-top: 30px;
        }
    }

    @media(min-width: 650px){ 
        #subnav-1 {
            margin-top: 30px;
        }
    }

    #subnav-collapse-1{
        position: absolute;
        width: 100%;
        padding-bottom: 10px;
        line-height:20px;
        overflow-y: auto;
        height: 680px;
        padding-bottom: 50px;
    }
    .menu-2 li {
        margin:  10px 0px 10px 25px;
    }

    .menu-3 li{
        margin:  10px 0px 10px 25px;
    }
    .section.active > .link-2 {
        color: #FFAD00;
    }
    
    /* menu-for mobile */
    @media(min-width: 200px){
        
        .sub-menu{
            background-color: rgba(75, 75, 75, 1);
            line-height: 40px;
            position: fixed;
            width: 100%;
            top: 45px;
        }
        .sub-menu .icon{
            float: right;
        }

        .subnav-content {
            background-color: #808080;
        }    
        #accordion-1> *, .left-menu, #accordion2-1 > * {
            border-bottom: 1px solid #ecebde;
        }

        .link-2 {
            font-size: 16px;
            padding: 5px 0;
        }

        .link-3 {
            font-size: 16px;
            
        }

        .lv-0, .lv-2{
            color: #ffc107;
            margin-bottom: 0px;
        }

        .lv-1 {
            color: #FFF;
            padding: 2px 0;
        }
    }

    /* Ipad */
    @media(min-width: 768px){
        #subnav-collapse-1{
            height: 600px;
        }

        #subnav-1{
            display:none;
        }

        .menu-3 li {
            padding:  2px;
        }
        .menu-3 .link-3 {
            color: #808080;  
        }

        .subnav-content, .sub-menu {
            background-color: transparent;
        }    
        .sub-menu{
            width: 30vw;
            position: relative;
            padding-top:10px;
            top:0;
        }
        .sub-menu .lv-0, 
        .sub-menu .lv-1, 
        .sub-menu .link-2,
        .sub-menu .link-3 {
            color: #808080;
            font-family: 'Frutiger Neue LT Book', sans-serif;
        }

        .link.lv-1, .link-2, .link-3 {
            font-size: 16px;
        }
        .link-2:hover, .link-3:hover{
            color: #da3305;
        }


    }
    /* full width */
    @media(min-width: 900px){

        .link.lv-1, .link-2, .link-3 {
            font-size: 18px;
        }


    }    
</style>
